const jets1 = require("./jets1.png");
const jets2 = require("./jets2.png");
const jets3 = require("./jets3.png");
const conf3 = require("./conf3.png");
const space1 = require("./space1.png");
const space2 = require("./space2.png");
const space3 = require("./space3.png");
const flix1 = require("./flix1.png");
const flix2 = require("./flix2.png");
const cat1 = require("./cat1.png");
const cat2 = require("./cat2.png");
const cat3 = require("./cat3.png");
const leader1 = require("./leader1.png");
const logo = require("./logo.png");
const coin1 = require("./coin1.png");
const coin2 = require("./coin2.png");
const coin3 = require("./coin3.png");
const kiu1 = require("./kiu1.jpg");
const kiu2 = require("./kiu2.jpg");
const kiu3 = require("./kiu3.jpg");
const kiu4 = require("./kiu4.jpg");
const zuri1 = require("./zuri1.jpg");
const zuri2 = require("./zuri2.jpg");
const zuri3 = require("./zuri3.jpg");
const zuri4 = require("./zuri4.jpg");

export {
  jets1,
  jets2,
  jets3,
  conf3,
  space1,
  space2,
  space3,
  flix1,
  flix2,
  cat1,
  cat2,
  cat3,
  leader1,
  logo,
  coin1,
  coin2,
  coin3,
  kiu1,
  kiu2,
  kiu3,
  kiu4,
  zuri1,
  zuri2,
  zuri3,
  zuri4,
};
