import React from "react";
import Header from "./Header";

const Home = () => (
  <>
    <Header />
    <section id="homepage" className="d-flex align-items-center min-vh-100 m-0">
      <div className="container ps-5 border-3 d-flex">
        <div>
          <h1 className="display-2 main-header">
            Full Stack Developer,
            <br />
            Aquinas Moriasi
          </h1>
          <p className="description text-light">
            As a full-stack developer, I specialize in creating dynamic,
            high-quality websites that deliver exceptional user experiences.
            Browse my portfolio to see firsthand the innovative solutions I've
            crafted. If you’re looking for a reliable partner to bring your
            project to life, don't hesitate to contact me through the form below
            or via the links on the left. Let's transform your ideas into
            reality!
          </p>
        </div>
        <ul className="contact-icons list-unstyled">
          <li>
            <a
              href="https://github.com/Aquinasmoriasi"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-github" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/aquinas-moriasi/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-linkedin" />
            </a>
          </li>
          <li>
            <a
              href="https://medium.com/@aquinasmoriasi/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-medium" />
            </a>
          </li>
          <li>
            <a
              href="mailto:aquinasmoriasi@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <i className="bi bi-envelope-fill" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  </>
);

export default Home;
