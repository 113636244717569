import React from "react";

const Contact = () => (
  <section id="about-me" className="pt-5">
    <div className="abt-description">
      <h2 className="about-header text-center text-decoration-underline">
        About Me
      </h2>
      <p className="text-light">
        I am currently a React Native Developer at Zuri Health. When I am not
        building cross-plaform app, I am upskilling and creating other exciting
        mobile and web apps. I am passionate about creating scalable, efficient,
        and user-friendly applications. I am always ready to learn new
        technologies and improve my skills. I am looking for a challenging role
        in a reputable organization to utilize my technical, database, and
        management skills for the growth of the organization as well as to
        enhance my knowledge about new and emerging trends in the IT sector.
      </p>
      <a
        href="https://docs.google.com/document/d/1vt62NbU5sn7S1dc1Li23fFnek4b9GV4E/edit?usp=sharing&ouid=106350340158040660894&rtpof=true&sd=true"
        className="resume btn rounded-1"
        target="_blank"
        rel="noreferrer"
      >
        Get My Resume
      </a>
    </div>
    <div id="contact-me">
      <h3 className="text-decoration-underline pt-4 text-center">Contact Me</h3>
      <form
        className="container d-flex flex-column justify-content-center"
        action="https://formsubmit.co/2b19f9cedf41319823f9bc594969b45a "
        method="POST"
      >
        <input type="hidden" name="_template" value="table" />
        <input type="hidden" name="_captcha" value="false" />
        <input
          type="hidden"
          name="_next"
          value="https://aquinasmoriasi.netlify.app/#contact-me"
        />
        <input
          type="text"
          className="mb-2 p-2 border-0 valid"
          id="namefield"
          name="Name"
          placeholder="Name"
          aria-label="Name"
          maxLength="30"
          required
        />
        <input
          type="email"
          className="mb-2 p-2 border-0 border-bottom"
          name="Email"
          placeholder="Email"
          aria-label="email"
          required
        />
        <textarea
          name="message"
          className="mb-3 p-2"
          rows="5"
          placeholder="Write your message here"
          maxLength="500"
          required
        />
        <button type="submit" className="git-btn rounded-1" id="submit">
          Get in Touch
        </button>
      </form>
    </div>
  </section>
);

export default Contact;
